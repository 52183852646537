.phone-input {
  .input-group {
    margin-bottom: 25px;
  }
}

.input-group {

  input {
    border: none;
    &:focus {
      outline: none;
    }
  }

  h3 {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
  }

  margin-bottom: 5px;

  .input {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background: #fffdf7;
    transition: .5s;

    &__disabled {
      opacity: .5;
    }

    .instruction {
      padding: 5px 15px;
      opacity: .5;

      border-top: 3px solid #dadada;

      .numbers {
        font-weight: 700;

        color: #cbcbcb;
      }

      p {
        padding: 0;
        margin: 0 2px 5px;
        line-height: 14px;
        font-size: 12px;
      }

      .phone-example {
        display: flex;
        width: 300px;font-size: 12px;


        & > div {
          padding: 0 2px;

          &:first-child {
            color: #505050;
          }
        }
      }
    }

    .placeholder {
      position: absolute;
      font-size: 14px;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      pointer-events: none;
      transition: .2s ease-in-out;
      color: #555;
      opacity: 1;
      transform: translateX(0);

      line-height: 14px;


      span {
        font-weight: 700;
        display: block;
        color: #777777;
      }
    }

    .hidden {
      transform: translateX(100%);
      opacity: 0;
    }

    input {
      height: 50px;
      font-size: 18px;
      width: 100%;
      padding: 0 15px;
      overflow: hidden;
      font-weight: 400;
    }

    .PhoneInput {

      .PhoneInputCountry {
        padding: 0  10px 0 15px;
        background: #fff;
        margin: 0;

        .PhoneInputCountryIcon--border {
          margin-right: 5px;
          box-shadow: none;
        }
      }

      input {
        padding: 0 15px 0 15px;
      }
    }
  }
}
