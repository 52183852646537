body {
  font-family: 'TildaSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.layout {

  display: flex;
  height: 100vh;

  .app-body {
    width: 100%;
    min-height: 600px;
    max-width: 500px;
    margin: auto;
  }
}

.app-body {

  .sysfont{
    font-size:12px; color: #777;
  }

  background: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;


  .heading {
    display: flex;
    align-items: center;

    .back {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #F40202;
      margin-right: 25px;
      transition: .3s;

      svg {
        fill: #F40202;
        transition: .3s;
        margin-left: -2px;
      }

      &:hover {
        background: #F40202;
        svg {
          fill: #fff;
        }
      }

    }
  }

  .mode-container {
    flex: 1;
    display: flex;

    & > div {
      width: 100%;
    }
  }

  .selection-block {
    margin: auto;
  }

  .form-block {
    margin: 35px 0;
  }



  h1, h2 {
    font-weight: 400;

    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }

  .text {
    color: #888;
    font-size: 18px;
    margin-bottom: 50px;
  }

  .selection {

    h2 {
      margin-bottom: 50px!important;
      text-align: center;
      color: #333;
    }

    .btn {
      padding: 15px;
      flex: 1;
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .checkbox-group {
    display: flex;
    margin: -15px;
    margin: 15px -15px;

    & > div {
      margin: 15px;
    }
  }

  .youtube {

    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: inherit;
      text-decoration: none;

      .label {
        font-weight: bold;
      }

      .icon {

        font-weight: bold;
        display: flex;
        align-items: center;

        img {
          width: 50px;
          margin-left: 25px;
        }
      }
    }

  }

  .btn {
    background: #F40202;
    border: 0;
    padding: 20px 50px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;

    cursor: pointer;
    user-select: none;

    &__dark {
      background: transparent;
      color: #333;
      border: 2px solid #333;
      font-weight: normal;
    }
  }

  .gdpr {
    user-select: none;
    display: flex;
    margin-top: 10px;
    align-items: center;
    color: #888;

    a {
      color: #888;
    }

    .checkbox {
      width: 15px;
      height: 15px;
      border: 1px solid #ccc;
      padding: 1px;
      margin-right: 15px;

      svg {

      }
    }

    &__text {
      font-size: 12px;
    }
  }
}

