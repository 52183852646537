.loading-button {

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  background: #F40202;
  border: 0;
  height: 60px;
  padding: 0 50px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;

  cursor: pointer;
  user-select: none;

  .loader {
    width: 25px;
    height: 25px;
    border: 4px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}