.checkbox-component {


  display: flex;
  align-items: center;
  user-select: none;

  .label {
    color: #555555;
  }

  .checkbox {
    border: 2px solid #ccc;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: flex;
    margin-right: 15px;
    cursor: pointer;

    svg {
      margin: auto;
      width: 25px;
      fill: #555;
    }
  }
}

